<template>
    <el-drawer :wrapperClosable="false" size="60%" title="地域设置" :with-header="false" :visible.sync="areaSetDrawer"
               @closed="closeDrawer" @opened="openDrawer"
               direction="rtl" custom-class="custom-drawer">
        <i @click="areaSetDrawer = false" class="btn-close el-icon-close"></i>
        <div class="custom-budget-content">
            <div class="title">地域设置</div>
            <div class="item">
                <el-checkbox @change="selectAll" :value="checkedAll" :indeterminate="isIndeterminate">全选</el-checkbox>
            </div>
            <el-cascader-panel v-model="areaValue" class="customPanel"
                               ref="multiplePanel"
                               :options="areaList" :props="props" collapse-tags clearable>
            </el-cascader-panel>

            <div class="operate-box">
                <el-button @click="sureAreaBtn" class="btn-w-140 btn-red" style="width: 120px">确定</el-button>
            </div>
        </div>
    </el-drawer>
</template>

<script>
    import { StuOpProGetLocation } from '@/utils/apis.js'
    export default {
        props: {
            ruleForm: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                areaSetDrawer: false,

                areaValue: [],
                areaList: [],
                props: {
                    multiple: true,
                    value: 'code',
                    label: 'name',
                },
            }
        },
        computed: {
            checkedAll() {
                const cityNumbers = this.areaList.reduce((total, next) => {
                    return total + next.children.length;
                }, 0);
                if (this.areaValue.length === cityNumbers) {
                    return true
                }
                return false;
            },
            isIndeterminate() {
                const cityNumbers = this.areaList.reduce((total, next) => {
                    return total + next.children.length;
                }, 0);
                if (this.areaValue.length === 0 || this.areaValue.length === cityNumbers) {
                    return false
                }
                return true;
            }
        },
        mounted() {
            this.getAreaList()
        },
        methods: {
            // 获取地区列表
            getAreaList() {
                StuOpProGetLocation().then((res) => {
                    this.areaList = res.data
                }).catch((err) => {})
            },
            // 打开抽屉
            openDrawer() {
                if(this.ruleForm.locationNumber==31){
                    this.ruleForm.position_data=[]
                }
                this.areaValue = this.ruleForm.position_data.reduce((current, next) => {
                    const children = next.children.map(item => {
                        return [next.code, item.code];
                    });
                    return [...current, ...children];
                }, [])
            },
            // 关闭抽屉
            closeDrawer() {
                if (this.areaValue.length === 0 || this.areaValue.length === 31) {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        position_data: [],
                        location_type: 1,
                        locationNumber: 31,
                    })
                } else {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        position_data: this.formatArea(this.areaValue),
                        locationNumber: this.areaValue.length,
                    })
                }
                this.areaValue = []
            },
            // 全选
            selectAll(val) {
                if (val) {
                    this.areaValue = this.areaList.reduce((current, next) => {
                        const children = next.children.map(item => {
                            return [next.code, item.code];
                        });
                        return [...current, ...children];
                    }, [])
                } else {
                    this.areaValue = []
                }
            },
            // 确定
            sureAreaBtn() {
                if (this.areaValue.length === 0) {
                    return this.$message.warning('请选择地区')
                } else {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        position_data: this.formatArea(this.areaValue),
                    })
                    this.areaSetDrawer = false
                }
            },
            formatArea(arrTmp) {
                let areaValue = JSON.parse(JSON.stringify(arrTmp))
                let arr = []
                arr = areaValue.reduce((current, next) => {
                    const index = current.findIndex(item => item.code === next[0]);
                    const areaInfo = this.areaList.find(area => area.code === next[0]);
                    const cityInfo = areaInfo.children.find(city => city.code === next[1]);
                    if (index !== -1) {
                        current[index].children.push({
                            code: cityInfo.code,
                            name: cityInfo.name,
                        });
                        return current;
                    }
                    current.push({
                        code: areaInfo.code,
                        name: areaInfo.name,
                        children: [
                            {
                                code: cityInfo.code,
                                name: cityInfo.name,
                            }
                        ]
                    })
                    return current
                }, [])
                return arr
            },
        }
    }
</script>

<style scoped lang="scss">
    .custom-budget-content {
        padding: 40px 60px;
        height: 100%!important;
        .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .item {
            color: #333;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .operate-box {
            .btn-red {
                position: absolute;
                right: 60px;
                bottom: 100px;
            }
        }
    }
    .customPanel {
        height: 250px;
        ::v-deep .el-cascader-menu {
            height: 100%;
            width: 50%;
            .el-cascader-menu__wrap {
                height: 100%;
                overflow-x: hidden;
            }
        }
    }
</style>