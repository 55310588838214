<template>
    <el-drawer :wrapperClosable="false" size="70%" title="添加资源位" :with-header="false"
               @opened="openDrawer" @closed="closeDrawer"
               :visible.sync="addResourceDrawer" direction="rtl" custom-class="custom-drawer">
        <i @click="addResourceDrawer = false" class="btn-close el-icon-close"></i>
        <div class="custom-budget-content">
            <div class="title">添加资源位</div>
            <div class="item blue">目前展位广告下的全部资源位均位于"商城站内"，建议结合营销目标和预算进行选择</div>
            <div class="item">已选择<span class="red">{{selectArr.length}}</span>个资源位</div>

            <el-table :data="resourcesData" border style="width: 100%;flex: 1; margin-top: 10px"
                      max-height="500" row-key="id" ref="chooseResource"
                      :cell-style="{height: '60px', color:'#343442', fontSize:'14px',fontFamily:'PingFang SC', fontWeight: 400}"
                      :header-cell-style="{fontWeight: 'normal', color: '#14141C', background: '#F5F5F5', height: '60px'}"
                      @selection-change="selectChange">
                <el-table-column reserve-selection type="selection" width="50" align="center"></el-table-column>
                <el-table-column label="资源位" width="200px">
                    <template slot-scope="scope">
                        <div class="resource-box">
                            <span class="text-overflow-2">{{scope.row.name}}</span>
                            <!-- 0 无线焦点图  1 分类   2 订单   3 pc右侧  4 pc首页  5  -->
                            <img :src="scope.row.type === 0 ? require('../../../../assets/images/student/infoPrompt/dibutonglan.png') :
                            scope.row.type === 1 ? require('../../../../assets/images/student/infoPrompt/dibutonglan.png') :
                            scope.row.type === 2 ? require('../../../../assets/images/student/infoPrompt/Mydibutonglan.png') :
                            scope.row.type === 3 ? require('../../../../assets/images/student/infoPrompt/PcRight.png') :
                            scope.row.type === 4 ? require('../../../../assets/images/student/infoPrompt/PcIndex.png')  :
                            require('../../../../assets/images/student/infoPrompt/Mydibutonglan.png')" alt="">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="customers" label="潜在买家数量"></el-table-column>
                <el-table-column prop="low" label="最低出价"></el-table-column>
                <el-table-column prop="avg" label="平均出价"></el-table-column>
                <el-table-column label="竞争热度" width="150px">
                    <template slot-scope="scope">
                        <el-rate v-model="scope.row.hot" disabled></el-rate>
                    </template>
                </el-table-column>
                <el-table-column prop="conversion_rate" label="转化率（%）"></el-table-column>
                <el-table-column prop="click_rate" label="点击率（%）"></el-table-column>
            </el-table>

            <div class="operate-box">
                <el-button @click="sureBtn" class="btn-w-140 btn-red" style="width: 120px">确定</el-button>
            </div>
        </div>
    </el-drawer>
</template>

<script>
    import { StuOpProGetResourcesData,StuOpProResourcesData } from '@/utils/apis.js'
    export default {
        props: {
            ruleForm: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                addResourceDrawer: false,
                resourcesData: [],
                selectArr: [],
                infoStepTwoRuleForm:{}
            }
        },
       
        mounted() {
          
            this.getResourceData()
        },
        methods: {
            // 获取资源位列表
            getResourceData() {
                if (localStorage.getItem('infoStepTwo')) {
                this.infoStepTwoRuleForm = JSON.parse(localStorage.getItem('infoStepTwo'))
                }
                let params = {
                    op_id: this.$route.query.op_id,
                    time_frame_data:this.infoStepTwoRuleForm.time_frame_data,
                }
                // StuOpProGetResourcesData(params).then((res) => {
                    StuOpProResourcesData(params).then((res) => {
                    let arr = res.data.data.map(item => {
                        return {
                            id: item.id,
                            type: item.type,
                            name: item.name,
                            potential_customers: item.potential_customers,
                            status: item.status,
                            click_rate: item.click_rate,
                            conversion_rate: item.conversion_rate,
                            customers: item.customers,
                            hot: item.hot,
                            low: res.data.minOffer,
                            avg: res.data.averageOffer,
                        }
                    })
                    this.resourcesData = arr
                })
            },
            // 打开抽屉
            openDrawer() {
                this.$refs.chooseResource.clearSelection();
                if (this.ruleForm.resources_data.length > 0) {
                    this.ruleForm.resources_data.forEach(row => {
                        this.$refs.chooseResource.toggleRowSelection(this.resourcesData.find(item => {
                            return row.id === item.id
                        }), true)
                    })
                    // console.log('this.selectArr',this.selectArr);
                   
                } else {
                    this.$refs.chooseResource.clearSelection();
                }
            },
            // 关闭抽屉
            closeDrawer() {

            },
            // 选择list
            selectChange(selection) {
                this.selectArr = selection
            },
            sureBtn() {
                if (this.selectArr.length === 0) {
                    return this.$message.warning('请至少添加一个资源位')
                } else {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        resources_data: this.selectArr
                    })
                    this.addResourceDrawer = false
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .custom-budget-content {
        padding: 40px 60px;
        height: 100%!important;
        /*display: flex;*/
        /*flex-direction: column;*/
        .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .item {
            color: #333;
            font-size: 16px;
            margin-bottom: 10px;
            &.blue {
                background: #D7E8FF;
                padding: 10px 30px;
            }
        }
        .red {
            color: #FD4446;
        }
        .operate-box {
            text-align: right;
            margin-bottom: 60px;
            margin-top: 50px;
        }
    }
    .resource-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>